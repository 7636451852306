.introHeader {
  display: flex;
  min-height: 18rem;
  justify-content: space-between;
  padding: 0 clamp(0rem, 10vw, 20rem) 0  clamp(0rem, 16vw, 20rem);
  position: relative;
  background-color: #000;
}

.introHeader h2 {
  font-weight: bold;
  color: var(--primary);
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
  font-weight: lighter;
  padding: 0 0;
  word-spacing: 0;
  margin: 0 0 2vh 0;
  white-space: pre-wrap;
  font-family: Rumble_Brave, sans-serif;
  font-size: 2.3rem;
}

.introHeader h3 {
  color: #fff;
  font-weight: lighter;
  padding: 0 0;
  word-spacing: 0;
  white-space: pre-wrap;
  font-size: 16px;
}

.headerIntroContainer {
  margin: 0;
}

.menuItem {
  display: flex;
  flex-direction: row;
  margin: 1vw;
  z-index: 1;
  height: 10%;
}

.introHeader h4 {
  color: #fff;
  padding: 8px 0;
  word-spacing: 0;
  margin: 0 1.8vw 0 0;
  cursor: pointer;
  font-size: 1.2rem;
}

.introHeader h4:hover {
  color: var(--secondary);
  transition: color 0.5s ease-in-out;
  word-spacing: 1px;
}

.introHeader h4.selectedText {
  background-color: var(--primary);
  color: #fff;
  padding: 8px;
  text-shadow: none;
  font-weight: bold;
}

.introLogo {
  width: 24vw;
  min-width: 18rem;
  object-fit: contain;
  position:absolute;
  top:6rem;
  left: 50%; 
  transform: translateX(-50%);
}

.clickableLogo {
  cursor: pointer;
}

/*SIZE FOR SMALL DEVICES*/
@media (max-width: 768px) {
  .introHeader {
    padding: 0vh 4vw 2vh;
    justify-content: space-between;
    flex-direction: column;
    min-height: 14vh;
  }

  .introHeader h2 {
    text-align: left;
    font-size: 1.8rem;
  }

  .introHeader h3 {
    font-size: 16px;
    text-align: left;
  }

  .headerIntroContainer {
    display: none;
  }

  .introHeader h4 {
    color: #ffffff;
    font-weight: lighter;
    padding: 0 0;
    word-spacing: 0;
    margin: 0 0;
    cursor: pointer;
    text-align: center;
    font-size: clamp(1rem, 1.4vw, 1.8rem);
  }

  .introLogo {
    width: 70vw;
    min-width: 16rem;
    position: absolute;
    top: 3rem;
  }

}

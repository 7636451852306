l.container {
  display: flex;
  flex-direction: column;
}

.wrapper {
  padding: 2vh 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrapper img {
  width: 56%;
  height: auto;
  margin: 0 0 2rem 0;
}

.wrapper h1 {
  color: var(--primary);
  padding: 1rem 0;
  font-weight: bold;
}

.wrapper h2 {
  color:#000;
  font-weight: bold;
  font-size: clamp(2.3rem ,3.5vw, 4rem) ;
}

.wrapper li {
  color:#000;
  font-size: clamp(1.6rem ,2vw, 3rem) ;
  margin-left: 1rem;
}

.footerStyle {
  background-color: var(--primary);
}

.footerStyle a,
.footerStyle h3,
.footerStyle h2
 {
  white-space: pre-wrap;
  color: #fff;
}


.footerStyle a:hover {
  color: #000;
}

@media (max-width: 768px) {
  .container {
  }

  .wrapper {
    padding: 2vh 0;
  }

  .wrapper img {
    width: 100%;
    padding: 0 1rem;
  }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush');
@import url('https://fonts.googleapis.com/css2?family=Geologica&display=swap');

@font-face {
    font-family: 'Rumble_Brave';
    src: local('Rumble_Brave'), url(./assets/fonts/Rumble_Brave.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Glacial_Indifference';
    src: local('Glacial_Indifference'), url(./assets/fonts/GlacialIndifference-Regular.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}



* {
    box-sizing: border-box;
}

html {
    font-family: 'Noto Sans JP', sans-serif;
}

body {
    margin: 0;
}

main {

}

:root {
    --font-family: Helvetica, serif;
    --background-color: #fefefe;
    --primary: #171615;
    --secondary: #d8a43e;
    --light-yellow-color: #f7d36f;
    --margin-horizontal: 8vw;
    --main-background-color: #444343;
    --header-background-color: #141414;
    --clickable-text-color: #52b472;
    --pink-color: #D9b3d6;
    --purple-color: #9ca4d6;
    --blue-color: #b4d6ed;
    --blue-strong-color: #034083;
    --pink-strong-color: #e334c6;
    --pink-color-02: #e085c1;
    --grey-color: #585e66;
    --red-color: #bc1823
}

.cursive-text {
  font-weight: bold;
  color: var(--primary);
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  font-weight: lighter;
  padding: 0 0;
  word-spacing: 0;
  margin: 0 0 2vh 0;
  white-space: pre-wrap;
  font-family: Rumble_Brave, sans-serif;
  font-size: 2.3rem;
}

a {
    white-space: pre-wrap;
    color: var(--purple-color);
    /*margin: 0 0 0 0;*/
    text-decoration: none;
}

a:hover {
    white-space: pre-wrap;
    color: var(--red-color);
    /*margin: 0 0 0 0;*/
    text-decoration: none;
    transition: color 0.5s ease-in-out;
}

h1,
h2,
h3,
h4,
label,
button,
li,
p {
    /* font-family: Geologica, serif; */
    font-family: Glacial_Indifference, sans-serif;
    color: #ffffff;
    font-weight: lighter;
    white-space: pre-wrap;
    margin:0;
    padding: 0;
    word-spacing: 1px;
}

.linear-gradient-bg {
    background: linear-gradient(
      130deg,
      #fff69d,
      #bf8b24,
      #fff69d,
      #fff69d,
      #bb8311,
      #fff7b3,
      #e7c74c
    );
}


@media (max-width: 768px) {
    h1 {
        font-size: clamp(1.7rem, 5vw, 5rem);
    }

    h2 {
        font-size: clamp(1.2rem, 4vw, 4rem);
    }

    h3 {
        font-size: clamp(0.9rem, 3vw, 3rem);
    }

    h4 {
        font-size: clamp(2vw, 2.5vw, 2.8vw);
    }

    a {
        font-size: clamp(1.5vw, 3vw, 4vw);
    }
}

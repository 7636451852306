.primaryBanner {
  display: flex;
  padding: 0 0;
  justify-content: space-between;
  margin-top: 0;
}

.imgContainer {
  display: flex;
  position: relative;
  margin: 0 0 0 2rem;
}

.primaryBannerImage {
  width: 22vw;
  height: 22vw;
  min-height: 20rem;
  min-width: 20rem;
  object-fit: cover;
  border-radius: 10px;
}

.primaryBanner h2 {
  background-color: transparent;
  font-weight: 600;
}

.textContainer {
  margin: 0 2rem 0 0;
}

.textContainer h2 {
  color: #000;
  margin-bottom: 1vh;
}

.textDescription {
  color: #000;
  font-size: 14px;
  font-weight: lighter;
  white-space: pre-wrap;
  margin: 0 0 2rem 0;
}

.primaryBannerImage {
  margin: 0 0 0;
  object-fit: contain;
  border-radius: 12px;
  min-height: 22rem;
  min-width: 22rem;
  align-self: center;
}

.carouselItem {
  height: 64vh;
  display: flex;
}

.carouselItem img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carouseTextContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center; /* Center align vertically */
  align-items: center;
  z-index: 1;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -30%);
}

.shopNameTitle{
  color: #000;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  margin: 1rem;
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .primaryBanner {
    display: flex;
    flex-direction: column;
    padding: 0 0;
  }

  .primaryBanner h2 {
    font-size: 1.6rem;
  }

  .textContainer {
    top: clamp(14rem, 65%, 90%);
  }

  .callNowBtn {
    padding: 0 0;
    z-index: 2;
  }

  .imgContainer {
    margin: 5rem 0 0 0;
    max-height: 22rem;
    max-width: 22rem;
    align-self: center;
  }

  .primaryBannerImage {
    margin: 0 0 0;
    object-fit: contain;
    border-radius: 12px;
    min-height: 22rem;
    min-width: 22rem;
    align-self: center;
  }

  .carouselItem {
    height: 66vh;
  }

  .carouselItem img {
    object-fit: cover;
  }
}

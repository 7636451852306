.wrapper {
  padding: 1vh 0 3vh 0;
  display: flex;
  flex-direction: column;
}

.wrapper h1 {
  margin: 0;
  text-align: center;
  font-family: Rumble_Brave, sans-serif;
  color: var(--primary);
  font-size: 4rem;
}

.pricingImage {
  display: block;
  margin: 0 auto;
  width: 70vw;
  object-fit: cover;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0 3vw;
  flex: 3;
}

.container h2 {
  text-align: center;
}

.serviceImg {
  object-fit: cover;
  width: 30vw;
  flex: 2;
  padding: 0 3vw 0;
}

.servicesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 clamp(9vw, 25vw, 23vw);
}

.serviceItem {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin: 0 8vw 1rem 0;
  justify-content: space-between;
  padding: 1rem;

  &:hover h4.change-color-on-hover {
    color: var(--primary);
    transition: color 0.2s ease-in-out;
  }
}

.serviceItem:hover {
  border: 1px solid #5a5e65;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2),
    -2px -2px 4px rgba(255, 255, 255, 0.2);
  animation: jump 0.5s ease;
}

.serviceItem:hover .roundDiv {
  animation: jump 0.3s ease;
}

.roundDiv {
  min-width: 8vw;
  min-height: 8vw;
  border-radius: 100%;
  transition: transform 0.3s ease;
  margin: 0 2vw 0 0;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-12px);
  }
}

.serviceItem h3 {
  white-space: pre-wrap;
  text-align: center;
  color: #000;
  margin: 2vh 0 0 0;
  text-align: left;
  font-weight: 600;
}

.serviceItem h4 {
  white-space: pre-wrap;
  text-align: center;
  color: #000;
  font-weight: lighter;
}

.serviceItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.menuTitle {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.menuTitle h2 {
  width: 23%;
  color: var(--primary);
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.rowItem {
  flex-direction: row;
  display: flex;
}

.pricingItem2 {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.pricingItem2 h3 {
  color: var(--grey-color);
  width: 100%;
}

.priceListBtn {
  align-self: center;
  min-width: 12rem;
}

.rowContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-right: 5vw;
}

.rowDirection {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 6vh 0 4vh 0;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0 0 0 0;
  }

  .container {
    flex-direction: column;
    padding: 0 0;
    justify-content: center;
    align-items: center;
    margin: 2vh 3vw;
    display: flex;
  }

  .wrapper h1 {
    margin: 2vh 0;
    font-size: 3rem;
  }

  .container h2 {
    text-align: center;
  }

  .servicesContainer {
    margin: 1vh 3vw;
  }

  .pricingImage {
    width: 100vw;
  }

  .roundDiv {
    min-width: 24vw;
    min-height: 24vw;
    margin: 0 4vw 0 0;
  }

  .serviceItem {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 0 0 3vh 0;
  }

  .serviceItem h3 {
    margin: 1vh 0 0 0;
    font-size: 1.1rem;
    text-align: left;
    font-weight: 600;
  }

  .serviceItem h4 {
    white-space: pre-wrap;
    font-size: 0.9rem;
    text-align: left;
    font-weight: lighter;
  }

  .menuPricing {
    border: 4px solid var(--blue-color);
    margin: 0vh 0vw 2vh 0;
    padding: 1vh 2vw;
  }

  .pricing {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }

  .pricing h3 {
    color: var(--grey-color);
    width: 23%;
  }

  .noMargin {
    margin: 0;
  }

  .rowDirection {
    flex-direction: column;
    display: flex-grow;
    margin: 4vh 0 6vh 0;
  }

  .serviceImg {
    width: 100%;
    flex: none;
    height : 55vh;
    object-fit: cover;
  }
  
}
